
import {Options, Vue} from 'vue-class-component';
import wallet from '@/common/web/wallet/wallet.vue';
import {ElTabs, ElTabPane} from 'element-plus';
import {Watch} from 'vue-property-decorator';
import {App, Format} from '@/services';

@Options({
  components: {
    wallet,
    ElTabs,
    ElTabPane
  }
})
export default class Proxy extends Vue {
  transitionName = 'slide-fade-right';
  centerWallet = '';
  currentTab = {
    name: 'ui_act_rp',
    routeName: 'ProxyReport'
  };
  mounted() {
    console.log(this.$route.name, 'this.$route.name', this.menus);
    // this.currentTab = this.menus.filter(v => v.routeName === this.$route.name)[0];

    this.currentTab = {...this.menus.filter(v => v.routeName === this.$route.name)[0]};
  }

  upperCase = Format.upperCase;
  menus = [
    {
      name: 'ui_act_rp',
      routeName: 'ProxyReport'
    },
    {
      name: 'ui_reg_act_back',
      routeName: 'ProxyReigster'
    },
    {
      name: 'ui_text_028',
      routeName: 'ProxyEverday'
    },
    {
      name: 'ui_text_029',
      routeName: 'ProxyOldBoy'
    }
  ];

  @Watch('$route')
  onChange() {
    const isBack = App.getIsRouterBack();
    App.setIsRouterBack(false);
    this.transitionName = isBack ? 'slide-fade-left' : 'slide-fade-right';
    this.currentTab = {...this.menus.filter(v => v.routeName === this.$route.name)[0]};
  }

  termClick(name: string) {
    this.$router.push({name});
  }

  setWallet(wallet: {centerWallet: string}) {
    this.centerWallet = wallet.centerWallet.replace(/,/g, '');
  }
}
